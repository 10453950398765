// buttons & links

.link {
  &--blue {
    color: $blue;
    transition: 0.3s;
    &:hover {
      color: $mid-purple;
    }
  }
  &--purple {
    color: $mid-purple;
    transition: 0.3s;
    &:hover {
      color: $blue;
    }
  }
}

.button-group {
  $self: &;
  display: flex;
  &--top {
    @extend #{$self};
    button {
      align-self: baseline;
    }
  }
  &--right {
    @extend #{$self};
    justify-content: flex-end;
  }
  &--left {
    @extend #{$self};
    justify-content: flex-start;
  }
}

.btn {
  &-primary--big {
    @extend .btn-primary;
    display: flex;
    align-items: center;
    //font-weight: $font-weight-bold;
    font-size: $font-size-m;
    .icon {
      font-size: 1.4rem;
    }
  }
  &-secondary {
    padding: 0.75rem;
    box-sizing: border-box;
    color: #0f2f51;
    background-color: #ffffffb8;
    border: solid 1px #0f2f51;

    b {
      font-weight: 600;
    }
    i {
      margin-left: 16px;
    }
  }
  &-secondary:hover {
    color: $white;
    background-color: #0f2f51;
  }

  &-outline-danger {
    padding: 0.75rem;

    b {
      font-weight: 600;
    }
    i {
      margin-left: 16px;
    }
  }

  &-unselected {
    @extend .btn-primary;
    background-color: #09203970;
    border: solid 1px #09203970;
  }

  &-action {
    $self: &;
    border-radius: 2pc;
    transition: $transition-normal/2;
    transform-origin: center;
    i {
      transition: $transition-normal/2;
      transform: scale(2) !important;
    }
    &:hover {
      background: $grey;
      i {
        color: $mid-purple;
      }
    }
    &--danger {
      @extend #{$self};
      &:hover {
        background: rgba($light-red, 0.2);
        i {
          color: $light-red;
        }
      }
    }
  }
}
