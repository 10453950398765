.launchers {
  &__description {
    @extend .small-body;
  }

  &__button {
    width: 50px;
    height: 50px;
  }
}
