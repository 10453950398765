.balance {
  &-load-status {
    cursor: pointer;

    &:hover {
      animation: rotate 1s linear infinite;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(-360deg);
        }
      }
    }
  }


  &-table {
    &-border {
      background-color: $grey;
      position: sticky;
      position: -webkit-sticky;
      top: -40px;
      width: 100%;
      height: 10px;
    }

    &-header {
      border-collapse: collapse;
      box-shadow: 0 -2px 15px var(--black) !important;
      position: sticky;
      position: -webkit-sticky;
      top: -30px;
    }

    &-body {
      --bs-table-bg: $grey;
    }
  }

  &-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;

    &-info {
      padding-left: 2px;
    }

    &-content {
      padding: 1rem;
    }

    &-border {
      border: 1px solid var(--black);
    }
  }

  &-pointer {
    cursor: pointer;
  }

  &-action {
    display: flex;
    justify-content: center;
    align-items: center;

    &-button {
      height: 36px;
      width: 36px;
    }
  }

  &-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-date-card {
    display: flex;
    align-items: center;
    height: 83px;

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 54px;

      &-title {
        font-size: $size-xl;
      }

      &-datepicker {
        flex-grow: 1;

        >div:first-child {
          width: 100% !important;
        }
      }
    }
  }

  &-link {
    font-weight: 700;
    text-decoration: underline;
  }

  &-badge {
    --badge-color: #8B36FC;
    color: var(--badge-color);
    border-color: var(--badge-color);
    background-color: #8B36FC2E;
    padding: 0.5rem;
    width: 10rem;
    cursor: default !important;
  }

  &-details {
    &-title {
      font-size: $size-l;
      font-weight: $poppins-bold-weight;
    }

    &-content {
      font-size: $size-m;
      font-weight: $poppins-light-weight;
    }
  }
}