.dashboard {
  &-date {
    display: flex;
    justify-content: space-between;
    width: 25%;
    padding-right: $main-margin/2;
  }

  &-month-switcher {
    @media screen and (min-width: 1400px) and (max-width: 2100px) {
      font-size: 1.5rem;
    }
  }

  &-graphs {
    &-title {
      display: flex;
      justify-content: space-between;

      &-hover {
        cursor: pointer;
      }
    }

    &-collapse-arrow {
      right: calc(-1 * var(--padding));
      align-self: center;
      position: relative;
    }
  }


  &-info {
    width: calc(75% - #{$main-margin});
    display: flex;
    justify-content: space-around;
    position: relative;
    padding-left: $main-margin;

    &:before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      border-left: solid 1px rgba($black, 0.1);
      left: 0;
    }

    &__content {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        color: $blue;
      }

      &-data {
        margin-left: $font-size-l;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .dashboard {
    &-date {
      justify-content: center;
      width: 100%;

      .switch-arrow {
        margin: 0 $main-margin/2;
      }
    }

    &-info {
      width: 100%;
      padding-left: 0;
      padding-top: $main-margin/2;
      margin-top: $main-margin/2;

      &:before {
        border-left: none;
        border-top: solid 1px rgba($black, 0.1);
        top: 0;
      }
    }
  }
}