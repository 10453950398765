$poppins-light-weight: 300;
$poppins-regular-weight: 400;
$poppins-medium-weight: 500;
$poppins-semibold-weight: 600;
$poppins-bold-weight: 700;

@font-face {
  font-family: "poppins";
  src: url(../../static/fonts/Poppins-Light.ttf) format("truetype");
  font-weight: $poppins-light-weight;
}

@font-face {
  font-family: "poppins";
  src: url(../../static/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: $poppins-regular-weight;
}

@font-face {
  font-family: "poppins";
  src: url(../../static/fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: $poppins-medium-weight;
}

@font-face {
  font-family: "poppins";
  src: url(../../static/fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: $poppins-semibold-weight;
}

@font-face {
  font-family: "poppins";
  src: url(../../static/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: $poppins-bold-weight;
}
