:root {
    --primary: #142F51;
    --light-primary: rgba(20, 47, 81, 0.59);
    --purple: #8B36FC;
    --light-purple: #8B36FC2E;
    --white: #FFFFFF;
    --black: rgba(0, 0, 0, 0.09);

    --grey: var(--grey-3);
    --grey-0: rgb(85, 85, 85);
    --grey-1: rgb(112, 112, 112);
    --grey-2: rgba(100, 101, 104, 0.612);
    --grey-3: rgba(112, 112, 112, 0.24);
    --grey-4: rgba(232, 232, 232, 0.41);

    --light-grey: var(--light-grey-2);
    --light-grey-0: #bbbbbb;
    --light-grey-1: #E8E8E8;
    --light-grey-2: #EFF2F7;

    --blue-0: #0A192D;
    --blue-1: var(--primary);
    --blue-2: #0E3C77;
    --blue-3: #0D539E;
    --blue-4: #4A6C99;

    --green: #39BA5C;
    --input-height: 43px;
    --padding: 18px;

    --small-padding: calc(var(--padding) / 2);
    --large-padding: calc(var(--padding) * 2);

    --font-size-body: 1rem;
    --font-weight-bold: 600;
    --font-weight-body: 400;
    --font-weight-light: 300;
    --font-family: "poppins", "courier new", monospace;

    --box-shadow: 0 0 var(--small-padding) var(--light-grey);
    --box-shadow-dark: 4px 7px 20px var(--black);
    --box-shadow-darker: 0px 0px 16px var(--grey-2);

    --background-svg-border-dashed: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='23' ry='23' stroke='%23333' stroke-width='1' stroke-dasharray='11%2c 11' stroke-dashoffset='95' stroke-linecap='square'/%3e%3c/svg%3e");
}