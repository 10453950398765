.billing {
  &-load-status {
    cursor: pointer;

    &-loading {
      animation: rotate 1s linear infinite;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(-360deg);
        }
      }
    }
  }


  &-table {
    &-border {
      background-color: $grey;
      position: sticky;
      position: -webkit-sticky;
      top: -40px;
      width: 100%;
      height: 10px;
    }

    &-header {
      border-collapse: collapse;
      box-shadow: 0 -2px 15px var(--black) !important;
      position: sticky;
      position: -webkit-sticky;
      top: -30px;
    }

    &-body {
      --bs-table-bg: $grey;
    }
  }

  &-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;

    &-info {
      padding-left: 2px;
    }

    &-content {
      padding: 1rem;
    }

    &-border {
      border: 1px solid var(--black);
    }
  }

  &-pointer {
    cursor: pointer;
  }

  &-action {
    display: flex;
    justify-content: center;
    align-items: center;

    &-button {
      height: 36px;
      width: 36px;
    }
  }

  &-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-location {
    font-weight: var(--font-weight-bold);
  }

  &-address {
    font-weight: var(--font-weight-light);
  }

  &-details-total {
    $details-total-border: 2px solid var(--purple);
    $details-total-radius: 10px;

    &-header {
      border: $details-total-border;
      border-bottom: none;
      border-radius: $details-total-radius $details-total-radius 0 0;
      background-color: var(--light-purple);
      // table padding offset correction
      margin-bottom: -0.5rem;
      padding-bottom: 0.5rem;
    }

    &-body {
      tr {
        &:last-child {
          td:last-child {
            div {
              border-bottom: $details-total-border;
              // table padding offset restoration
              margin-bottom: 0;
              padding-bottom: 0;
              border-radius: 0 0 $details-total-radius $details-total-radius;
            }
          }
        }

        td:last-child {
          div {
            border: $details-total-border;
            border-top: none;
            border-bottom: none;
            background-color: var(--light-purple);
            // table padding offset correction
            margin-top: -0.5rem;
            padding-top: 0.5rem;
            margin-bottom: -0.5rem;
            padding-bottom: 0.5rem;
          }
        }
      }
    }
  }
}