.rotate-0 {
  transition: $transition-normal;
  transform: rotate(0deg);
}

.rotate-180 {
  transition: $transition-normal;
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}
