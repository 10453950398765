.game {
  &-container {
    padding: $main-margin 0;
    position: absolute;
    width: 100%;
  }

  &-tooltip {
    &:hover {
      cursor: pointer;
      & > .game-tooltip-content {
        @include transition(
          background $transition-quick/2 ease-in-out,
          color $transition-quick/2 ease-in-out
        );
        visibility: visible;
        color: white;
      }
    }
    &-content {
      @include transition(0 ease-in-out);
      visibility: hidden;
      position: absolute;
      z-index: 2;
      background: $mid-purple;
      color: transparent;
      margin-bottom: 0;
      max-width: 35%;
      padding: $main-margin/4;
      &:before {
        top: -20px;
        content: " ";
        display: block;
        left: 10%;
        position: absolute;
        border-color: transparent transparent $mid-purple transparent;
        border-style: solid;
        border-width: 11px;
      }
    }
  }

  &-show {
    display: flex;
    flex-direction: column;
    height: 100%;
    &-header {
      display: flex;
      justify-content: space-between;
      &__information {
        padding-left: 26px;
        &-container {
          display: flex;
        }
      }
    }

    &__main-bloc {
      background: white;
      margin-top: $main-margin;
      flex-grow: 1;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      &-container {
        position: relative;
        overflow-y: auto;
        flex-grow: 1;
      }

      .tabs {
        width: 100%;
        background: $grey;
        display: flex;
        border-bottom: solid 1px white;
        .basic-multi-select {
          min-width: 110px;
        }

        .select__indicators {
          color: $blue;
        }

        .select__single-value {
          font-size: $font-size-s;
        }

        &-item {
          color: rgba($blue, 0.6);
          text-decoration: none;
          display: flex;
          font-weight: $poppins-bold-weight;
          align-items: center;
          height: $main-margin;
          background: $grey;
          border-style: none;
          border-left: solid 5px transparent;
          padding: 0 $main-margin/2;
          transition: 0.3s;
          z-index: 0;
          i {
            color: rgba($blue, 0.6);
          }

          &.active,
          &:hover {
            background: white;
            color: $mid-purple;
            border-color: $mid-purple;
            box-shadow: 5px -5px 8px rgba(0, 0, 0, 0.08);
            z-index: 1;
            i {
              color: $mid-purple;
            }
          }
          &--div {
            // fix tabs with select
            @extend .tabs-item;
            &:hover {
              a {
                color: $mid-purple;
                text-decoration: none;
              }
            }
            &.active,
            &:hover {
              a {
                color: $mid-purple;
              }
            }
          }
        }
      }
    }
  }
}
.not-active {
  color: $red;
  opacity: 0.6;
  pointer-events: none;
}

.spaces {
  &-tooltip {
    &:hover {
      cursor: pointer;
      & > .spaces-tooltip-content {
        @include transition(
                        background $transition-quick/2 ease-in-out,
                        color $transition-quick/2 ease-in-out
        );
        visibility: visible;
        color: white;
      }
    }
    &-content {
      @include transition(0 ease-in-out);
      visibility: hidden;
      position: absolute;
      z-index: 2;
      background: $mid-purple;
      color: transparent;
      margin-bottom: 0;
      max-width: 66%;
      padding: $main-margin/4;
      &:before {
        top: -20px;
        content: " ";
        display: block;
        left: 10%;
        position: absolute;
        border-color: transparent transparent $mid-purple transparent;
        border-style: solid;
        border-width: 11px;
      }
    }
  }
}