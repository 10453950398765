body {
  color: $blue;
  font-family: "poppins";
  font-size: 16px;
}

a {
  font-weight: 700;
}

p {
  margin-bottom: 0;
}

textarea {
  border: none;
}
