.unauthenticated {

  &__fixed {
    position: fixed;
  }

  &__absolute {
    position: absolute;
    right: 0;
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $blue;
    background-image: url(../../static/images/julius-drost-1078800-unsplash.png);
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
  }

  &__right {
    align-items: center;
    flex-direction: column;
    display: flex;
    height: 100vh;
    justify-content: center;
  }
}
