$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$input-border-color: $blue;
$input-padding-x: 0;
$input-padding-x-lg: 0;
$input-padding-x-sm: 0;
$input-focus-box-shadow: none;
$enable-shadows: false;
$primary: $blue;
// $green from bootstrap is overrided by $green variable from _variables.scss
// $success: $green;
$warning: $gold;
$danger: $light-red;
$body-bg: $grey;
$font-family-base: "poppins";
$nav-link-padding-y: 1.25rem;
$nav-link-padding-x: 0.7rem;
$breadcrumb-bg: $white;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-active-color: $blue;
$headings-color: $blue;
$headings-font-family: "poppins";
$custom-select-bg: transparent;
$custom-select-border-color: $blue;
$custom-select-color: $blue;
$custom-select-font-weight: $poppins-semibold-weight;
$input-btn-font-family: "poppins";
$custom-select-indicator: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");
$link-decoration: none;

$h1-font-size: 3.5rem;
$h2-font-size: 2.7rem;
$h3-font-size: 2rem;
$h4-font-size: 1.3rem;
$h5-font-size: 1.3rem;
$h6-font-size: 1.1rem;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/tooltip";

@import "bootstrap/scss/utilities/api";

// @import "bootstrap/scss/custom-forms";

.breadcrumb-item {
  font-size: 1.1rem;
  display: flex;
  padding-left: 0 !important;
  transition: $transition-quick;
  &.active {
    font-weight: $poppins-semibold-weight;
  }

  &::before {
    display: none !important;
  }

  .disable-link {
    pointer-events: none;
    font-weight: $poppins-regular-weight;
  }

  a {
    @extend .link--blue;
    text-decoration: none !important;
  }

  p {
    margin-bottom: 0;
  }

  i {
    color: $mid-purple;
    font-size: 1rem;
    margin: 5px $size-l 0;
  }
}

%label-wize {
  bottom: 13px !important;
  font-size: 18px !important;
  font-weight: $poppins-semibold-weight;
}

%label-wize-onState {
  bottom: 40px !important;
  font-size: $font-size-xs !important;
  font-weight: $poppins-regular-weight;
}

%placeholder {
  font-size: $font-size-s;
  color: transparent;
  font-weight: $poppins-light-weight;
  transform: scale(0);
  transform-origin: left;
  transition: opacity 0.2s 0s ease-out, color 0.5s 0s ease-out,
    transform 0.2s 0s ease-out;
  font-style: italic;
}

%placeholder-state {
  color: rgba($black, 0.4);
  transform: scale(1);
}

%placeholder-shown {
  font-size: $font-size-s;
  opacity: 0;
  transition: 0.2s 0s ease-out;
}

%placeholder-shown-state {
  opacity: 1;
  font-size: $font-size-s;
  transition: color 0.5s 0.1s ease-out, font-size 0.2s 0.1s ease-out;
}

%input-icon {
  position: absolute;
  right: 0;
  transition: $transition-quick ease all;
  bottom: $size-l;
  font-size: $font-size-l;
}

.form-control[type="email"],
.form-control[type="number"] {
  &:invalid ~ .label-wize {
    @extend %label-wize;
  }
  &:focus ~ .label-wize,
  &:valid ~ .label-wize,
  &:invalid:not(:focus):not(:placeholder-shown) ~ .label-wize {
    @extend %label-wize-onState;
  }
}

textarea {
  opacity: 1 !important;
  &::placeholder {
    font-size: 1rem !important;
    transform: scale(1) !important;
  }
}

.form-control,
.form-control-lg,
.form-control-sm {
  border: 0;
  color: $blue;
  font-size: $font-size-l;
  padding-bottom: 0;
  display: block;
  border: none;
  width: calc(100% - 30px);
  font-weight: $poppins-semibold-weight;
  height: $main-margin;
  position: relative;
  background: transparent;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;

  &:placeholder-shown {
    @extend %placeholder-shown;
  }

  &::placeholder {
    @extend %placeholder;
  }

  &:valid,
  &:focus {
    &:placeholder-shown {
      @extend %placeholder-shown-state;
    }

    &::placeholder {
      @extend %placeholder-state;
    }
  }

  &.simple-placeholder {
    &::placeholder {
      font-style: normal;
      font-size: $font-size-m;
      color: $blue !important;
      font-weight: $poppins-light-weight;
      transform: scale(1);
      transition: $transition-quick;
      opacity: 1;
    }

    &:placeholder-shown {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
    color: $blue;
    background: transparent;
  }

  &:valid:not(:focus):not(:required) {
    & ~ .label-wize {
      @extend %label-wize;
    }
    &:placeholder-shown {
      @extend %placeholder-shown;
    }
    &::placeholder {
      transform: scale(0);
    }
  }

  &:invalid ~ .label-wize,
  &:not(:required) ~ .label-wize {
    @extend %label-wize;
  }

  &:focus ~ .label-wize,
  &:focus:not(:required) ~ .label-wize,
  &:valid:not(required):not(:placeholder-shown) ~ .label-wize,
  &:valid:not(:focus):not(:required):not(:placeholder-shown) ~ .label-wize {
    @extend %label-wize-onState;
  }

  & ~ .icon {
    @extend %input-icon;
  }

  &:hover ~ .icon {
    //opacity:1;
    //transition:$transition-quick ease all;
  }

  &:invalid,
  &:not(output):-moz-ui-invalid {
    box-shadow: none;
  }

  &.is-invalid:focus,
  &.is-valid:focus {
    box-shadow: none;
    border-bottom-width: 2px;
  }
}

.form-group,
.form-row {
  padding: $size-xs 0;
  box-sizing: content-box;
  position: relative;
  .label-wize {
    font-size: $size-m;
    font-weight: $poppins-semibold-weight;
    position: absolute;
    pointer-events: none;
    left: 0;
    margin: 0;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    color: $blue;
  }
  .single-label {
    @extend .single-label;
  }
}

.form-control {
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  &-input {
    z-index: 8;
    opacity: 0;
    height: 100%;
    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      & ~ .form-control-label {
        border-color: rgba($blue, 0.5);
      }
    }
  }

  &-label {
    border: solid $main-margin/4 $grey;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: $main-margin/4 0;
    transition: $transition-normal;
    position: absolute;
    @extend .btn-text;
    z-index: 5;

    &:after {
      background: white;
      border: dashed 1px $blue;
      position: absolute;
      width: 100%;
      height: 100% !important;
      content: "" !important;
      z-index: -1;
    }
  }
}

.custom-control-input {
  background: red !important;
  &:active {
    background: red !important;
  }
}

.list-inline-item {
  font-weight: $poppins-semibold-weight;
  &:not(:last-child) {
    &::after {
      bottom: 3px;
      content: fa-content($fa-var-circle);
      font-family: "Font Awesome 6 Pro";
      font-size: 0.3em;
      font-style: normal;
      font-weight: 900;
      left: 4px;
      position: relative;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-weight: 700;
}

h5,
.h5,
h6,
.h6 {
  font-weight: 600;
}

.nav-link {
  border-left: 10px solid $blue;
  color: $white;
  font-size: $font-size-l;
  font-weight: $poppins-light-weight;
  display: flex;
  align-items: center;
  transition: $transition-normal;
  padding-top: 0;
  padding-bottom: 0;

  p {
    margin-top: var(--padding);
    margin-bottom: var(--padding);
    flex-grow: 2;
  }

  &.active,
  &:active,
  &:focus,
  &:hover {
    border-left-color: $dark-blue;
    background-color: $dark-blue;
    outline: none !important;
  }

  &.active {
    border-left-color: $mid-purple;
    font-weight: $poppins-medium-weight;
  }

  &:active,
  &:focus,
  &:hover {
    color: $white;
  }
}

//table
.table {
  --bs-table-bg: white;

  color: $blue !important;
  thead th {
    border-top: none !important;
    border-bottom: solid 2px rgba($blue, 0.4) !important;
  }
  tbody td {
    border-top: Solid 1px rgba($blue, 0.4) !important;
    min-height: $main-margin;
  }
}
