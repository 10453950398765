.select,
.multi-select {
  &__value-container {
    padding-left: 0 !important;
  }
  &__single-value {
    font-weight: $poppins-semibold-weight;
    font-size: $font-size-m;
  }
  &__control {
    &,
    &:focus {
      border-radius: 0 !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: solid 1px $blue !important;
      box-shadow: none !important;
    }
  }
  &__menu {
    margin: 0 !important;
    border-radius: 0 !important;
    z-index: 5 !important;
    &-list {
      padding: 0 !important;
    }
  }
  &__option {
    border-bottom: 1px solid $grey;
    display: flex !important;
    align-items: center !important;
    height: $main-margin;
    &:last-child {
      border-bottom: none;
    }
  }
}

.multi-select {
  &__multi-value {
    border-radius: 2pc !important;
    background: $blue !important;
    color: white !important;
    margin-right: 10px !important;
    padding-left: 10px !important;
    padding-right: 25px !important;
    position: relative;
    transition: $transition-quick;
    &__label {
      color: white !important;
    }
    &__remove {
      background: transparent;
      position: absolute;
      width: $size-l;
      height: 100%;
      right: 0;
      border-radius: 0 50% 50% 0 !important;
      cursor: pointer;
      color: white !important;
      transition: $transition-quick;
      svg {
        right: 5px;
        position: absolute;
      }
      &:hover {
        background: $red !important;
      }
    }
  }
}
