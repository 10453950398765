.rdrDateRangePickerWrapper, .rdrCalendarWrapper {
    font-size: var(--font-size-body);
    text-align: left;
    font-family: var(--font-family);
    color: var(--primary);
}

.rdrDateRangePickerWrapper {
    border-bottom: solid var(--grey) 1px;
}

.rdrDefinedRangesWrapper {
    width: 170px;
    border-right: solid var(--grey) 1px;
}

.rdrStaticRanges {
    display: flex;
    align-content: space-between;
}

.rdrStaticRange {
    border: none;
    background: var(--white);
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
    background: var(--light-grey);
}

.rdrStaticRangeSelected {
    text-decoration: underline;
}

.rdrStaticRangeLabel {
    display: block;
    padding: var(--small-padding) var(--padding);
    text-align: left;
    color: var(--light-primary);
}

.rdrStaticRangeSelected .rdrStaticRangeLabel {
    color: var(--primary);
    font-weight: var(--font-weight-bold);
}

.rdrInputRanges {
    display: none;
}

.rdrMonthAndYearWrapper {
    align-items: center;
    padding: var(--small-padding) var(--padding);
    border-bottom: solid var(--grey) 1px;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    padding: var(--small-padding) var(--large-padding) var(--small-padding) var(--small-padding);
    outline: 0;
    color: var(--primary);
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    text-align: center;
    text-transform: capitalize;
    font-weight: var(--font-weight-bold);
}

.rdrMonthAndYearPickers select:hover{
    background-color: var(--light-grey);
}

.rdrMonthPicker, .rdrYearPicker{
    margin: 0 var(--small-padding);
}

.rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 var(--padding);
    padding: 0;
    border: 0;
}

.rdrNextPrevButton:hover{
    background: var(--light-grey);
}

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
}

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
}

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
}

.rdrWeekDays {
    padding: 0 0.833em;
}

.rdrMonth{
    padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
}

.rdrWeekDay {
    font-weight: 400;
    line-height: 2.667em;
    color: rgba(20, 47, 81, 0.59);
    text-transform: capitalize;
}

.rdrDay {
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    line-height: 28px;
    height: 28px;
    text-align: center;
}

.rdrDay:focus {
    outline: 0;
}

.rdrDayNumber {
    outline: 0;
    font-weight: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 5px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rdrDayNumber span {
    color: #142F51;
    font-weight: 500

}

.rdrDayPassive .rdrDayNumber span {
    color: rgba(20, 47, 81, 0.59);
    font-weight: 300
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: currentColor;
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    bottom: -4px;
}

.rdrSelected{
    left: 2px;
    right: 2px;
}

.rdrStartEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
}

.rdrEndEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
}

.rdrSelected{
    border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0;
}

/* ==== Imported from react-date-picker library as is ==== */

.rdrCalendarWrapper {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Added */
    background: var(--white);
    font-size: var(--font-size-body);
}

.rdrDateDisplay{
    display: flex;
    justify-content: space-between;
}

.rdrDateDisplayItem{
    flex: 1 1;
    width: 0;
    text-align: center;
    color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
}

.rdrDateDisplayItem input{
    text-align: inherit
}

.rdrDateDisplayItem input:disabled{
    cursor: default;
}

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
    box-sizing: inherit;
    display: flex;
    justify-content: space-between;
}

.rdrMonthAndYearPickers{
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

select {
    font-size: inherit;
}

.rdrNextPrevButton {
    box-sizing: inherit;
    cursor: pointer;
    outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
    display: flex;
    width: 380px;
    padding: var(--small-padding) var(--padding);
}

.rdrMonthsVertical{
    flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
    display: flex;
    flex-direction: row;
}

.rdrWeekDays{
    display: flex;
}

.rdrWeekDay {
    flex-basis: calc(100% / 7);
    box-sizing: inherit;
    text-align: center;
}

.rdrDays{
    display: flex;
    flex-wrap: wrap;
    row-gap: calc(var(--font-size-body)/2);;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
    overflow: auto;
}

.rdrDateRangeWrapper{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDateInput {
    position: relative;
}

.rdrDateInput input {
    outline: none;
}

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: var(--font-size-body);
    line-height: var(--font-size-body);
    top: 0;
    right: .25em;
    color: #FF0000;
}

.rdrDay {
    box-sizing: inherit;
    width: calc(100% / 7);
    position: relative;
    font: inherit;
    cursor: pointer;
}

.rdrDayNumber {
    display: block;
    position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
}

.rdrDayDisabled {
    cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
    .rdrDay {
        flex-basis: 14.285% !important;
    }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
    display: inline-flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
    display: flex;
    flex-direction: column;
}

.rdrStaticRange{
    font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
    display: flex;
}

.rdrInputRangeInput{}
