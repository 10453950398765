.date-range-container {
  border: solid $blue 1px;
  width: 380px;
  height: 40px;
  position: relative;
}

.date-range-container .button-group--right {
  margin: 16px;
  height: 40px;
}

.date-range-container .button-group--right button {
  margin-left: 16px;
}

.date-range-input {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-range-input__month-btn{
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue;
  color: $white;
}

.date-range-input__range-label {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  i {
    padding-right: 1rem;
  }
}

