.users {
  &__button {
    width: 30px;
    height: 30px;
  }

  &-load-more {
    width: fit-content;
  }
}
