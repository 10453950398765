.authenticated {
  height: 100vh;
  flex-wrap: nowrap;

  &-launcher {
    $download-color: #94EDFF;
    a {
      background-color: $download-color;
      border-color: $download-color;
      color: var(--primary);
      &:hover {
        background-color: $download-color;
        border-color: var(--primary);
        color: var(--primary);
      }
    }
  }

  &__header,
  &__footer {
    background-color: $white;
    flex-grow: 0;
  }

  &__footer {
    text-align: center;
    border-top: solid 2px $grey;
    height: 30px;
    font-size: $font-size-xs;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__header {
    display: flex;
    min-height: 60px;
    padding-left: $main-padding;
    padding-right: 0;
    border-bottom: solid 2px $grey;

    &__breadcrumb {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &-profile {
      z-index: $foreground-z-index;
      margin-bottom: 0;
      padding-left: 0;
      cursor: pointer;
      justify-content: flex-end;
      min-width: $size-l;

      .li-parent {
        width: 100%;
        background-color: $blue;

        .icon-user {
          font-size: 30px;
          margin-left: $main-margin/2;
          transition: $transition-normal;
          color: $white;
          margin: 2px;
          padding: 5px;

          &:before {
            border-left: solid 1px rgba($white, 0.4);
            padding-left: 10px;
            line-height: 30px;
            display: inline-block;
          }
        }

        .user-info {
          color: $white;
          display: flex;
          align-items: center;
          margin-left: 10px;
          padding: 7px;
          transition: $transition-normal;
        }

        .li-child {
          background: transparent none;
          width: 100%;
          height: $main-margin;
          //padding:0 $main-margin/2;
          border-bottom: solid 2px $grey;
          transition: $transition-normal;
          &:last-child {
            border-bottom: none;
          }

          &__item {
            transition: $transition-normal;
            padding: 0 $main-margin/2;

            $item: &;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: $blue;
            white-space: nowrap;
            text-decoration: none;
            p {
              margin-bottom: 0;
            }
          }

          &:hover {
            background: $blue;
            .li-child__item {
              color: white;
            }
          }
        }

        &:hover,
        &:active,
        &:focus {
          & .ul-child {
            max-height: 400px;
            transition: $transition-normal ease-out;
          }
        }
      }

      &:hover {
        .icon-user,
        & .user-info {
          color: $mid-purple;
        }
      }

      & .li-parent,
      & .li-child {
        position: relative;
        display: inline-block;
      }

      & .ul-child {
        position: absolute;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
        right: 0;
        max-height: 0;
        padding: 0;
        background-color: white;
        overflow: hidden;
        transition: 0.3s ease-out;
      }
    }
  }

  &__main {
    padding: $main-padding;
    overflow-y: auto;
  }

  .logo {
    display: flex;
    img {
      width: 180px;
      margin: $main-margin/2 auto $main-margin;
    }
  }

  .icon-container {
    width: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-right: 10px;
  }

  .switch-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition-quick;
    cursor: default;
    &:hover {
      div {
        background: $grey;
        cursor: pointer;
      }

      i {
        color: $mid-purple;
      }
    }

    div {
      transition: $transition-quick;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-radius: 2pc;
    }

    i {
      transition: $transition-quick;
      position: relative;
      cursor: pointer;
    }
  }
}
