.main-modal {
  background: rgba($dark-blue, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__container {
    background: white;
    min-width: 500px;
    padding: $main-margin;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__title {
    text-align: center;
    margin-bottom: $font-size-xs;
  }

  &__buttons {
    $self: &;
    display: flex;
    justify-content: space-between;
    margin: $font-size-xs 0;

    &--right {
      @extend #{$self};
      justify-content: flex-end;
    }
  }
}

.table {
  &__tr {
    &:hover {
      background: $grey;
      cursor: pointer;
    }
  }
}
