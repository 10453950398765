@import "theme.css";

@import "./Base/variables";
@import "./Base/fonts";
@import "./Base/font-icon";
@import "./Base/reset";
@import "./Base/mixin";

@import "./Dependencies/fontawesome";
@import "./Dependencies/bootstrap";

@import "./Components/text";
@import "./Components/button";
@import "./Components/badge";
@import "./Components/card";
@import "./Components/center";
@import "./Components/filter";
@import "./Components/form";
@import "./Components/loader";
@import "./Components/selectList";
@import "./Components/modal";
@import "./Components/pegi";
@import "./Components/round";
@import "./Components/collapse";
@import "./Components/global";
@import "./Components/sidebar";
@import "./Components/thumbnail";
@import "./Components/listCard";
@import "./Components/balanceCard";
@import "./Components/balanceModal";
@import "./Components/selectLanguage";
@import "./Components/notification";
@import "./Components/balanceGamesModal";
@import "./Components/selectLauncher";

@import "./Layout/authenticated";
@import "./Layout/unauthenticated";

@import "./Patterns/consumption";
@import "./Patterns/games";
@import "./Patterns/index";
@import "./Patterns/tokens";
@import "./Patterns/company";
@import "./Patterns/game/show";
@import "./Patterns/game/infos";
@import "./Patterns/support";
@import "./Patterns/profile";
@import "./Patterns/login";
@import "./Patterns/reset-password";
@import "./Patterns/terms-of-use";
@import "./Patterns/phone-country-select";
@import "./Patterns/launchers";
@import "./Patterns/billing";
@import "./Patterns/users";
@import "./Patterns/balance";
@import "./Patterns/game-sessions";
@import "./Patterns/session-config";

.color-blue {
  color: $blue;
}

.color-purple {
  color: $mid-purple;
}

.border-right {
  border-right: 1px solid $light-purple;
}

.pointer {
  cursor: pointer;
}
