$margin: (
    ".main-margin",
    $main-margin,
    $main-margin,
    $main-margin,
    $main-margin
  ),
  (".main-margin-y", $main-margin, 0, $main-margin, 0),
  (".main-margin-x", 0, $main-margin, 0, $main-margin),
  (".main-margin-t", $main-margin, 0, 0, 0),
  (".main-margin-r", 0, $main-margin, 0, 0),
  (".main-margin-l", 0, 0, 0, $main-margin),
  (".main-margin-b", 0, 0, $main-margin, 0);

@each $name, $top, $right, $bottom, $left in $margin {
  #{$name} {
    margin: $top $right $bottom $left !important;
  }
}

//@mixin prefix($props, $prefixes: webkit moz ms o) {
@mixin prefix($props, $prefixes: ("webkit", "moz", "ms", "o")) {
  @each $param, $value in $props {
    @if $prefixes {
      @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $param}: #{$value};
      }
    }
    #{$param}: #{$value};
  }
}
