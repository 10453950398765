.listCardContainer {
  width: 100%;
  color: var(--primary);
  
  background: var(--light-grey-1);
  box-shadow: 4px 7px 20px var(--grey-3);
  filter: drop-shadow(4px 7px 20px var(--grey-3));
  
  button {
    padding: 0.5rem;
  }

  .actionContainer {
    float: right;
  }
}
