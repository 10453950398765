.sidebar {
  --max-width-large: 300px;
  --max-width-small: 80px;
  --button-collapse-size: 22px;

  position: relative;
  background-color: $blue;
  max-width: var(--max-width-large);
  height: 100%;
  overflow-y: scroll;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-content {
    display: inherit;
    flex-direction: inherit;
    justify-content: inherit;
    height: 100vh;
  }

  &-logo-container {
    height: fit-content;
    display: flex;
    justify-content: center;
  }

  &-nav {
    width: 100%;
    flex-grow: 1;

    &-collapsed {
      @extend .sidebar-nav;
      transition: left 0.3s ease-in-out;
    }
  }

  &-collapsed {
    @extend .sidebar;
    max-width: 80px;
    background-color: $blue;

    .row.flex-column {
      height: 100%;
    }
  }

  .nav-link {
    max-width: var(--max-width-large);
    height: fit-content;
    transition: left $transition-normal ease-in-out;

    &.child {
      &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        background-color: $mid-purple;
        border-radius: 50%;
        left: var(--padding);
        opacity: 0;
      }

      &:not(.active) {
        &:hover {
          position: relative;

          &::before {
            opacity: 0.5;
          }
        }
      }

      &.active {
        position: relative;
        border-left-color: #031B30;

        &::before {
          opacity: 1;
        }
      }
    }

    &.offset {
      padding-left: var(--large-padding);
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      max-width: var(--max-width-large);
    }

    img {
      margin-top: var(--padding);
      margin-bottom: var(--padding);
    }

    svg {
      margin-top: var(--padding);
      margin-bottom: var(--padding);
    }

    &-collapsed {
      @extend .nav-link;
      max-width: var(--max-width-small) !important;
      transition: right $transition-normal ease-in-out;

      &.child {
        padding-left: var(--padding);

        &::before {
          left: 5px;
        }
      }

      &.active,
      &:active,
      &:focus,
      &:hover {
        max-width: var(--max-width-small) !important;
      }

      i {
        margin-top: var(--padding);
        margin-bottom: var(--padding);
      }
    }
  }

  .button-collapse {
    width: 100%;
    max-width: var(--max-width-large);
    display: flex;
    justify-content: right;
    align-items: flex-end;
    padding: var(--padding);
    display: flex;
    justify-content: center;
    background-color: rgb($dark-blue, 0.2);

    img {
      width: var(--button-collapse-size);
      height: var(--button-collapse-size);
      padding: 0;
      transform: rotate(180deg);
    }

    &-collapsed {
      @extend .button-collapse;
      max-width: var(--max-width-small);
      justify-content: center;

      img {
        transform: rotate(0);
      }
    }
  }

  .badge-collapsed {
    position: absolute;
    opacity: 0.9;
  }

  .logo {
    &-collapsed {
      img {
        margin: $main-margin auto $main-margin !important;
        width: 60px;
      }
    }
  }
}