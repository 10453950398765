.expanded-window {
  box-sizing: border-box;
  background: $grey;
  height: 100px;
  transition: $transition-normal;
  overflow: hidden;
  position: relative;
  pre {
    font-family: monaco, consolas, "courier new", monospace;
    min-height: 3em;
    overflow: auto;
    padding: 1em;
    width: 100%;
    float: left;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, $grey 70%);
    transition: $transition-normal;
    opacity: 1;
    left: 0;
    z-index: 1;
  }

  .main-field {
    padding-bottom: $main-margin/2;
  }

  &.active {
    max-height: 9999px;
    transition: $transition-normal;
    &:before {
      z-index: -1;
      opacity: 0;
      animation: changeZindex $transition-normal linear $transition-normal;
      animation-fill-mode: forwards;
    }
  }
}

.expanded-btn {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;

  button {
    display: flex;
    align-items: center;
    border: none;

    background: $blue;
    color: white;
    padding: 0 $font-size-l;
    transition: $transition-normal;
    transform: translateY(-$main-margin/4);
    &:hover {
      opacity: 0.8;
    }
  }
}

@keyframes changeZindex {
  0% {
    z-index: 1;
  }
  100% {
    z-index: -1;
  }
}
