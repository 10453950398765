.badge {
  &-enable {
    background: $blue;
  }
  &-disable {
    background: rgba($blue, 0.3);
  }

  $self: &;
  color: white;
  padding: 4px 10px;
  &-switch {
    position: absolute;
    right: 0;
    cursor: pointer;
    @extend #{$self};
    padding: 4px 24px 4px 1px;
    transition: 0.3s ease-out;
    &__container {
      display: flex;
      align-items: center;
    }

    &__label {
      margin-bottom: 0;
      border-radius: 2pc;
      background: white;
      padding: 2px 10px;
      margin-left: 5px;
      color: rgba($blue, 0.7);
    }

    i {
      transition: 0.3s;
      transform: scale(0);
      position: absolute;
    }

    & .show-enable {
      transform: scale(0);
      left: 10px;
    }

    & .show-disable {
      transform: scale(1);
      right: 10px;
    }
  }

  &.enable {
    padding: 4px 5px 4px 24px;
    & .show-enable {
      transform: scale(1);
    }

    & .show-disable {
      transform: scale(0);
    }

    .badge-switch__label {
      color: $blue;
    }
  }
}
