.balance-card {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: calc(var(--bs-gutter-x) * 1);
  }

  &-body {
    width: fit-content;
    color: var(--primary);
    font-size: $font-size-l;
  }

  &-content {
    font-weight: bold;
  }

  &-date {
    padding-top: 0.5rem;
    font-weight: bold;
    font-size: calc($font-size-l * 0.5);
  }
}