.balance-modal {
  &-link {
    color: var(--white);
    text-decoration: underline;
    cursor: pointer;
    padding-left: 0.5rem;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}