.input-container {
  position: relative;
  & .liner {
    margin-top: 5px;
    height: 1px;
    width: 100%;
    background: $blue;
    transition: $transition-quick;
  }
}

.form-error {
  color: $red;
}

.single-label {
  $self: &;
  color: $blue;
  margin-bottom: $main-margin/6;
  &--bold {
    @extend #{$self};
    font-weight: $poppins-bold-weight;
  }
}

.main-field {
  color: $blue;
  background: $grey;
  min-height: $main-margin;
  padding: $main-margin/4;
  display: inline-block;
  margin-bottom: 0;
  font-weight: $poppins-regular-weight;
  font-size: $font-size-s !important;
  opacity: 0.9;

  &-channel-activated, &-release-unavailable {
    padding: 0.75rem;

    b {
      font-weight: 600;
    }

    i.fa-check {
      margin-left: 22px;
      color: #39ba5c;
    }
  }

  &-release-unavailable {
    i.fa-trash-check, i.fa-trash-plus {
      margin-left: 22px;
      color: #39ba5c;
    }
  }

  &-release-unavailable {
    i.fa-trash-check, i.fa-trash-plus {
      margin-left: 15px;
      color: $light-red;
    }
  }
}

.group-label {
  display: flex;
  & > div {
    margin-right: $main-margin;
  }

  &-release-header {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }
}

textarea {
  background: $grey !important;
  padding: $font-size-s !important;
  width: 100% !important;
  font-size: $font-size-s !important;
}

.uploaded-files {
  &__file {
    transition: $transition-normal;
    border-bottom: solid 2px white;
    background: $grey;
    min-height: $main-margin;
    align-items: center;
    display: flex;
    padding: $main-margin/4;
    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: auto !important;
      width: 75%;
    }

    .icon--delete {
      position: absolute;
      opacity: 0;
      transform: scale(0);
      pointer-events: none;
    }

    .icon--valid {
      position: absolute;
    }

    .icon--loading {
      animation: spin-loader $transition-normal * 3 linear infinite;
    }

    p,
    i {
      transition: $transition-normal;
    }

    p {
      color: $blue;
      margin-bottom: 0;
      margin-right: $main-margin/4;
    }

    &.isError {
      background: rgba($light-red, 0.2) !important;
      p,
      i {
        color: $light-red;
      }
    }

    &:hover {
      &.isLoaded {
        background: rgba($light-red, 0.2) !important;
        .icon--delete {
          opacity: 1;
          transform: scale(1);
          cursor: pointer;
          pointer-events: auto;
        }

        .icon--valid {
          opacity: 0;
          transform: scale(0);
        }
        p {
          color: $light-red;
        }
      }
    }

    &-action {
      $self: &;
      width: $size-l;
      height: $size-xl;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__container {
    width: 70px;
    height: 70px;
    border: solid 1px $blue;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: $blue;
      font-size: 30px;
    }
  }
}

[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  & + .pegi {
    filter: grayscale(50%);
  }

  &:checked + .pegi {
    transition: filter 0.5s, outline 0.5s;
    outline: solid 3px $blue;
    filter: grayscale(0%);
  }
}

.form-floating, label:after {
  background-color: transparent !important;
}
