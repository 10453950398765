.balance-games-modal {
  &-close {
    align-self: center;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-content {
    background-color: #F2F2F2;
    width: 80%;
    padding: 37px 67px;
    font-size: $size-m;
    list-style: inside;
    list-style-type: '- ';
  }
}