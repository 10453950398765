.session-config {

  &_teams {
    text-align: center;
    font-weight: var(--font-weight-bold);
    font-size: calc(var(--font-size-body) * 1.2);
    margin: 10px 0;
  }

  &_section-title {
    margin-bottom: 10px;
    font-weight: var(--font-weight-light);
  }

  &_table {
    --left: 10px;

    border: 1px solid $black;
    margin-bottom: 20px;

    tr {
      position: relative;

      &::after {
        border-bottom: 1px solid var(--grey-2);
        content: '';
        position: absolute;
        left: var(--left);
        bottom: 0;
        width: calc(100% - var(--left) * 2);
      }

      th,
      td {
        width: 50%;
        padding: 10px;
        font-weight: var(--font-weight-bold);
        text-align: center;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;

          &::after {
            display: none;
          }
        }

        td {
          font-weight: var(--font-weight-light);
          word-break: break-all;
        }
      }
    }
  }
}