.reset-password-form {
  font-size: $font-size-m;
  background-color: $white;
  font-weight: $poppins-light-weight;
  padding-top: 7rem;
  padding-bottom: 7rem;

  &__sign-in {
    align-items: center;
    display: flex;
    font-size: 1rem;
  }
  .icon {
    bottom: 18px;
  }
}
