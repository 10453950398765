.game-sessions {
  &-table {
    &-border {
      background-color: $grey;
      position: sticky;
      position: -webkit-sticky;
      top: -40px;
      width: 100%;
      height: 10px;
    }

    &-header {
      border-collapse: collapse;
      box-shadow: 0 -2px 15px var(--black) !important;
      position: sticky;
      position: -webkit-sticky;
      top: -30px;
    }

    &-body {
      --bs-table-bg: $grey;
    }
  }

  &-pointer {
    cursor: pointer;
  }

  &-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 5px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 2rem;
    }
  }

  &-players {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-input {
    height: 100%;
    width: 100%;
    color: var(--primary);
    padding: 0px 10px;
    border: 1px solid var(--primary);
    border-radius: 0;

    &::placeholder {
      opacity: 1;
    }

    &:focus {
      outline: $black solid 1px !important;
    }
  }

  &-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}