.games {
  &-button {
    &-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.15));
      gap: 1rem;
    }

    cursor: pointer;
    width: 100%;
    background-color: #142F51;
    color: white;
    padding: 0.5rem 1rem;
  }

  &-card {
    &-container {
      height: 100%;
      width: 100%;
    }

    &-link {
      &-container {
        height: 100%;
      }

      &-cover {
        max-height: 158px;
        max-width: 158px;
        height: 100%;
        width: 100%;
      }

      &-list {
        flex-wrap: wrap;

        &-content {
          padding: 0 0.5rem;
          font-weight: $poppins-semibold-weight;
          font-size: $font-size-m;
          position: relative;

          &:not(:last-child) {
            &::after {
              bottom: calc($font-size-m / 2);
              content: fa-content($fa-var-circle);
              font-family: "Font Awesome 6 Pro";
              font-size: 0.3em;
              font-style: normal;
              font-weight: 900;
              right: -0.3em;
              position: absolute;
            }
          }
        }
      }

      .card-label {
        font-family: "poppins", "courier new", monospace;
        font-weight: $poppins-light-weight;
        font-size: $font-size-s;
      }

      p,
      ul {
        margin-bottom: 0;
        font-size: $font-size-m;
        font-weight: $poppins-medium-weight;
      }

      h4 {
        font-size: 30px
      }

      p,
      li,
      h4,
      .games-card-link-list-content {
        @include transition($transition-quick);
      }

      &:hover {
        text-decoration: none !important;

        p,
        li,
        h4,
        .games-card-link-list-content {
          color: $purple;
        }

        .games-card-link__btn {
          .icon {
            color: $mid-purple;
            transform: translateX(0px);
          }
        }
      }

      &__btn {
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include border-radius(50%);
        @include transition($transition-quick);

        div {
          text-decoration: none;
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icon {
          @include transition($transition-quick ease-in-out);
          font-size: 2rem;
          transform: translateX(-10px);
        }
      }
    }
  }
}