@mixin pegi($limit, $color) {
  background-color: $color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: $font-size-s;
  width: 40px;
  height: 40px;
  transition: filter 0s, outline 0.5s;
  outline: solid 3px transparent;
  &::before {
    content: $limit;
    display: block;
    font-weight: $poppins-semibold-weight;
    position: relative;
    text-align: center;
    margin-bottom: 0;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
    font-size: $font-size-xl;
  }
}

.pegi-7 {
  @include pegi("7", $green);
}
.pegi-12 {
  @include pegi("12", $gold);
}
.pegi-16 {
  @include pegi("16", $light-red);
}
.pegi-18 {
  @include pegi("18", $light-red);
}

.pegi-text {
  margin-top: -5px;
  width: 40px;
  font-size: 10px;
  font-weight: 700;
  font-family: Poppins;
  text-align: center;
  color: white;
  background-color: black;
}
