.icon-col {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 45px;
}

.profile {
  &-form {
    i {
      color: $blue;
    }
    .main-field {
      margin-top: 5px;
      width: 100%;
    }
  }
}
