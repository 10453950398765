.round {
  align-items: center;
  border-radius: 50%;
  border: 1px solid $blue;
  display: flex;
  justify-content: center;
  position: relative;

  &--65 {
    height: 65px;
    width: 65px;

    img {
      width: 50px;
      position: relative;
    }
  }
  .picto {
    font-size: 55px;
  }
}
