.loading {
  $self: &;
  color: $blue;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: $grey;
  padding: 1rem;
  z-index: 1;

  &--left {
    align-items: start;
  }

  &--no-padding-x {
    @extend .px-0;
  }

  &--large {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &--white {
    @extend #{$self};
    background: transparent;
    > * {
      background: transparent !important;
    }
  }

  i {
    animation: spin-loader $transition-normal * 3 linear infinite;
  }
  &-container {
    position: relative;
    max-width: 100%;
    height: 150px;
  }
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
