.picto-blue {
  display: inline-block;

  --dimension: 25px;
  height: var(--dimension);
  width: var(--dimension);

  mask-image: url(../../static/images/picto-launchers.svg);
  mask-repeat: no-repeat;
  mask-size: contain;

  background-color: #0e3c77; 
}

.picto-blue:has(+ div[aria-selected="true"])  {
  @extend .picto-blue;
  background-color: white;
}