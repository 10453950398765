$light-blue: #d5f6fc;
$mid-blue: #94edff;
$blue: #0f2f51;
$dark-blue: #001b30;

$light-purple: #e1cbff;
$mid-purple: #8a36fd;
$dark-purple: #370c71;

$gold: #fdc64b;
$green: #66d17b;
$light-red: #fc4777;

$white: #ffffff;

$sky-blue: #29a4ff;
$blue-grey: #a1bbce;
$grey: #f1f1f1;

$salmon: #fed0a2;
$coral: #f78484;
$magenta: #ff58ff;
$celadon: #12c7b2;

$main-margin: 3rem; //48px
$main-padding: 40px;

$transition-normal: 0.5s;
$transition-quick: 0.3s;

$font-size-xs: 0.875rem; //14px
$font-size-s: 1rem; //16px
$font-size-m: 1.125rem; //18px
$font-size-l: 1.25rem; //20px
$font-size-xl: 1.375rem; //22px

$size-xs: 14px;
$size-s: 16px;
$size-m: 18px;
$size-l: 20px;
$size-xl: 22px;

$foreground-z-index: 100;