.select-language {
  position: relative;


  &-flag {
    width: 50px;
    height: 15px;
  }

  &-dropdown {
    background-color: white;
    list-style: none;
    display: none;

    &-item {
      &:hover {
        background-color: var(--primary);
      }
    }
  }

  &-selected {
    background-color: $light-blue;
  }

  &:hover {
    .select-language {
      &-dropdown {
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
      }
    }
  }
}