.company {
  .compagny-form {
    &__cover {
      width: 100%;
      height: 150px;
    }
  }

  .company-header {
    &:hover {
      background-color: darkgray;

      .icon-company {
        display: none;
      }

      .icon-download {
        color: $white;
        display: inline;
      }
    }
  }

  .icon-download {
    display: none;
  }

  .company-logo {
    width: 100%;
    height: 100%;
    border-radius: 33px;
  }



}

.company-modal-title {
  font-size: calc($font-size-m * 2);
  color: $black;
}

.file-list-header {
  div {
    border-bottom: 1px solid $primary;
  }
}

.img-preview {
  height: 45px
}

.company-file-upload-container {
  height: 150px;
}