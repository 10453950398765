.title {
  color: $blue;
  font-size: 1.5rem; //24px
  font-weight: $poppins-bold-weight;
}

.single-label,
.small-body {
  margin: 0;
  font-size: $font-size-s;
  color: $blue;
  font-weight: $poppins-regular-weight;
}

.btn-text {
  font-weight: $poppins-semibold-weight;
  font-size: $font-size-m;
  color: $blue;
}
