// custom icon font
@font-face {
  font-family: "octopod";
  src: url("../../static/fonts/octopod-font-icons/octopod.eot");
  src: url("../../static/fonts/octopod-font-icons/octopod.eot")
      format("embedded-opentype"),
    url("../../static/fonts/octopod-font-icons/octopod.ttf") format("truetype"),
    url("../../static/fonts/octopod-font-icons/octopod.woff") format("woff"),
    url("../../static/fonts/octopod-font-icons/octopod.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  $self: &;
  font-family: "octopod" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $blue;
  font-size: 1rem;
  &--white {
    @extend #{$self};
    color: white !important;
  }
  &--valid {
    color: $green;
  }
  &--delete {
    color: $light-red;
  }
}
$icon-size: ("s", $font-size-s), ("m", $font-size-m), ("l", $font-size-l),
  ("xl", $font-size-xl);

@each $name, $size in $icon-size {
  .icon-#{$name} {
    font-size: $size;
    @extend .icon;
  }
}
$icons: ("add", "\e91b"), ("alert", "\e90c"), ("ask", "\e911"),
  ("check", "\e913"), ("company", "\e922"), ("dev", "\e90a"),
  ("disabled", "\e914"), ("document", "\e921"), ("download", "\e912"),
  ("edit", "\e900"), ("expand", "\e910"), ("info", "\e907"), ("key", "\e904"),
  ("loader", "\e915"), ("lock", "\e920"), ("main-arrow", "\e918"),
  ("mask", "\e919"), ("mask-add", "\e903"), ("media", "\e908"),
  ("players", "\e923", 2.8rem), ("problem", "\e90b"), ("put-in-prod", "\e90e"),
  ("release", "\e906"), ("reload", "\e909"), ("save", "\e90f"),
  ("session", "\e925", 2.8rem), ("succes", "\e917"), ("time", "\e902", 2.8rem),
  ("trash", "\e91a"), ("upload", "\e91c"), ("user", "\e901"),
  ("waiting", "\e916");

@each $name, $code, $size in $icons {
  .icon-#{$name}:before {
    content: #{$code};

    @if $size {
      font-size: $size;
    }
  }
}

@each $name, $code, $size in $icons {
  .icon-#{$name}:before {
    content: $code;

    @if $size {
      font-size: $size;
    }
  }
}
